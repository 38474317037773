import React from 'react';
import './Footer.css'; // Ensure this file contains the updated CSS

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="container">
                <p>&copy; {new Date().getFullYear()} . All rights reserved.</p>
                <ul className="legal-links">
                    <li><a href="impressum.html">Impressum</a></li>
                    <li><a href="privacy-policy.html">Privacy Policy</a></li>
                    <li><a href="terms-of-service.html">Terms of Service</a></li>
                    <li><a href="cookie-policy.html">Cookie Policy</a></li>
                    {/* Uncomment the next line if you have an accessibility statement */}
                    {/* <li><a href="/accessibility-statement">Accessibility Statement</a></li> */}
                </ul>
            </div>
        </footer>
    );
}

export default Footer;
