import React from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { BinData } from './types';

interface CostHistogramProps {
    bins: BinData[];
    setSelectedBin: (bin: BinData) => void;  // It should only accept BinData, not null
}


const CostHistogram: React.FC<CostHistogramProps> = ({ bins, setSelectedBin }) => {
    const svgRef = React.useRef<SVGSVGElement | null>(null);
    const { t } = useTranslation(); // Initialize translation hook

    React.useEffect(() => {
        if (!svgRef.current || bins.length === 0) {
            d3.select(svgRef.current).selectAll('*').remove();
            return;
        }

        const margin = { top: 20, right: 30, bottom: 70, left: 60 };
        const width = 500 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;

        d3.select(svgRef.current).selectAll('*').remove();

        const svg = d3.select(svgRef.current)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .attr('role', 'img')
            .attr('aria-labelledby', 'histogramTitle histogramDesc')
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Define the pattern for non-best bins
        const patterns = svg.append('defs');
        patterns.append('pattern')
            .attr('id', 'patternStripes')
            .attr('width', 4)
            .attr('height', 4)
            .attr('patternUnits', 'userSpaceOnUse')
            .append('rect')
            .attr('width', 2)
            .attr('height', 4)
            .attr('transform', 'translate(0,0)')
            .attr('fill', 'sbb-color-midnight');

        const x = d3.scaleBand()
            .domain(bins.map(d => d.label))
            .range([0, width])
            .padding(0.1);

        const y = d3.scaleLinear()
            .domain([0, d3.max(bins, d => d.value) || 0])
            .range([height, 0]);

        // Tooltip
        const tooltip = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('position', 'absolute')
            .style('text-align', 'center')
            .style('width', '150px')
            .style('padding', '8px')
            .style('font', '14px sans-serif')
            .style('background', 'rgba(0, 0, 0, 0.7)')
            .style('color', '#fff')
            .style('border-radius', '4px')
            .style('pointer-events', 'none')
            .style('opacity', 0);

        svg.selectAll('.bar')
            .data(bins)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', d => x(d.label)!)
            .attr('y', height)
            .attr('width', x.bandwidth())
            .attr('height', 0)
            .attr('fill', d => d.isBest ? 'sbb-color-orange' : 'url(#patternStripes)')
            .on('click', function (event, d) {
                setSelectedBin(d);
            })
            .transition()
            .duration(800)
            .attr('y', d => y(d.value))
            .attr('height', d => height - y(d.value));

        // Interactivity: Hover effect
        svg.selectAll('.bar')
            .on('mouseover', function (event, d) {
                const binData = d as BinData;
                d3.select(this).attr('fill', binData.isBest ? 'darkorange' : '#555');
                tooltip.transition()
                    .duration(200)
                    .style('opacity', 1);
                tooltip.html(`${t('histogram.label', { label: binData.label })}<br/>${t('histogram.cost')}: ${binData.value.toFixed(2)}`)
                    .style('left', (event.pageX + 10) + 'px')
                    .style('top', (event.pageY - 30) + 'px');
            })
            .on('mouseout', function (event, d) {
                const binData = d as BinData;
                d3.select(this).attr('fill', binData.isBest ? 'orange' : 'url(#patternStripes)');
                tooltip.transition()
                    .duration(500)
                    .style('opacity', 0);
            });

        // Y Axis
        svg.append('g')
            .call(d3.axisLeft(y));

        // X Axis Label
        svg.append('text')
            .attr('transform', `translate(${width / 2},${height + margin.bottom - 20})`)
            .style('text-anchor', 'middle')
            .text(t('histogram.xAxisLabel'));

        // Y Axis Label
        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0 - margin.left)
            .attr('x', 0 - (height / 2))
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .text(t('histogram.yAxisLabel'));

        return () => {
            tooltip.remove();
        };
    }, [bins, t]);

    return (
        <div className="sbb-grid sbb-spacing-responsive-m">
            <div className="sbb-col sbb-align-center sbb-justify-center">
                <svg ref={svgRef}></svg>
            </div>
        </div>
    );
};

export default CostHistogram;
