import React, {useState} from 'react';
import WelcomeMessage from './WelcomeMessage';
import InteractiveIntro from './InteractiveIntro';
import './styles.css';
import Footer from "./Footer";

const ChatbotPanel = () => {
    return (
        <div className="chatbot-panel">
            <WelcomeMessage/>
            <Footer/>
        </div>
    );
};

export default ChatbotPanel;
