import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';
import ChatbotPanel from './components/ChatbotPanel';
import MetaTags from './components/MetaTags';
import { SbbContainer } from '@sbb-esta/lyne-react/container';

const AppWithLanguage = () => {
    const { lang } = useParams();
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [currentLang, setCurrentLang] = useState(i18n.language);

    const supportedLanguages = ['en', 'de', 'fr', 'it', 'rm'];

    useEffect(() => {
        if (lang && !supportedLanguages.includes(lang)) {
            navigate('/en', { replace: true });
            return;
        }

        if (lang && lang !== i18n.language) {
            i18n.changeLanguage(lang).then(() => {
                setCurrentLang(lang);
            });
        }
    }, [lang, i18n, location, navigate]);

    const handleLanguageChange = (event: CustomEvent) => {
        const selectedLanguage = (event.target as HTMLSelectElement).value;

        if (supportedLanguages.includes(selectedLanguage) && selectedLanguage !== i18n.language) {
            i18n.changeLanguage(selectedLanguage).then(() => {
                setCurrentLang(selectedLanguage);
                navigate(`/${selectedLanguage}`);
            });
        }
    };

    return (
        <SbbContainer>
            <LanguageSwitcher onChange={handleLanguageChange} currentLanguage={currentLang} />
            <MetaTags />
            <ChatbotPanel />
        </SbbContainer>
    );
};

export default AppWithLanguage;
