import React from 'react';
import { useTranslation } from 'react-i18next';
import { BinData } from './types';
import { SbbTableWrapper } from '@sbb-esta/lyne-react/table';

interface CostDetailsProps {
    data: BinData;
}

const CostDetails: React.FC<CostDetailsProps> = ({ data }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const { label, value, computationalDetails } = data;
    const { list_of_abos, half_fare_card_details } = computationalDetails;

    return (
        <SbbTableWrapper>
            <h2>{t('details.subscriptionOverview')}</h2>
            <table className="sbb-table">
                <thead>
                <tr>
                    <th>{t('details.labelAboCombination')}</th>
                    <th>{t('details.totalCostCHF')}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{label}</td>
                    <td>{value.toFixed(2)}</td>
                </tr>
                </tbody>
            </table>

            <h3>{t('details.halfFareCardDetails')}</h3>
            <table className="sbb-table">
                <thead>
                <tr>
                    <th>{t('details.from')}</th>
                    <th>{t('details.to')}</th>
                </tr>
                </thead>
                <tbody>
                {half_fare_card_details.hta_abo_periods.map((period, index) => (
                    <tr key={index}>
                        <td>{period.from}</td>
                        <td>{period.to}</td>
                    </tr>
                ))}
                <tr>
                    <td><strong>{t('details.totalCost')}</strong></td>
                    <td>{half_fare_card_details.cost.toFixed(2)}</td>
                </tr>
                </tbody>
            </table>

            <h3>{t('details.listOfAbos')}</h3>
            <table className="sbb-table">
                <thead>
                <tr>
                    <th>{t('details.name')}</th>
                    <th>{t('details.from')}</th>
                    <th>{t('details.end')}</th>
                    <th>{t('details.costCHF')}</th>
                </tr>
                </thead>
                <tbody>
                {list_of_abos.map((abo, index) => (
                    <tr key={index}>
                        <td>{abo.name}</td>
                        <td>{abo.from_date}</td>
                        <td>{abo.end_date}</td>
                        <td>{abo.cost.toFixed(2)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </SbbTableWrapper>
    );
};

export default CostDetails;
