import React from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom'; // To get the current path

const MetaTags: React.FC = () => {
    const {t, i18n} = useTranslation();
    const location = useLocation();

    // Define the base URL for your website
    const baseUrl = "https://aboadvisor.ch";

    // Define supported languages and their hreflang values
    const languages = [
        {code: "en", name: "English"},
        {code: "de", name: "Deutsch"},
        {code: "fr", name: "Français"},
        {code: "it", name: "Italiano"},
        {code: "rm", name: "Rumantsch"},
    ];

    // Get the current language code from i18n
    const currentLang = i18n.language;

    // Get the current path, stripping the language code from the start
    let currentPath = location.pathname.replace(/^\/(en|de|fr|it|rm)/, '');

    // If we're on the root or index.html, treat it as the English version
    if (location.pathname === '/' || location.pathname === '/index.html') {
        currentPath = ''; // Treat as the English homepage
    }

    // Function to generate the alternate href for each language
    const getAlternateHref = (lang: string) => {
        // For English, handle root and index.html as equivalent
        /*   if (lang === 'en') {
               return currentPath === '' ? `${baseUrl}/index.html` : `${baseUrl}/en${currentPath}`;
           }*/
        return `${baseUrl}/${lang}${currentPath}`;
    };

    return (
        <Helmet>
            {/* Basic Meta Tags */}
            <meta name="description" content={t('meta.description')}/>
            <meta name="keywords" content={t('meta.keywords')}/>
            <meta name="author" content={t('meta.author')}/>

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={t('meta.ogTitle')}/>
            <meta property="og:description" content={t('meta.ogDescription')}/>
            <meta property="og:image" content="/favicon.ico"/>
            <meta property="og:url" content={`${baseUrl}/${currentLang}${currentPath}`}/>
            <meta property="og:type" content="website"/>

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={t('meta.twitterTitle')}/>
            <meta name="twitter:description" content={t('meta.twitterDescription')}/>
            <meta name="twitter:image" content="/favicon.ico"/>
            <meta name="twitter:url" content={`${baseUrl}/${currentLang}${currentPath}`}/>

            <link rel="canonical" href={getAlternateHref(currentLang)}/>

            {/* Hreflang Links for SEO */}
            {languages.map((lang) => (
                <link
                    key={lang.code}
                    rel="alternate"
                    href={getAlternateHref(lang.code)}
                    hrefLang={lang.code}
                />
            ))}

            <link rel="alternate" href={`${baseUrl}/en`} hrefLang="x-default"/>


            {/* Title */}
            <title>{t('meta.ogTitle')}</title>
        </Helmet>
    );
};

export default MetaTags;
