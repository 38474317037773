import React from 'react';
import { useTranslation } from 'react-i18next';
import { BinData } from './types';
import { SbbTableWrapper } from "@sbb-esta/lyne-react/table";
import { SbbSecondaryButton, SbbTertiaryButton } from "@sbb-esta/lyne-react/button";

interface CostTableProps {
    bins: BinData[];
    setSelectedBin: (bin: BinData) => void;  // It should only accept BinData, not null
}


const CostTable: React.FC<CostTableProps> = ({ bins, setSelectedBin }) => {
    const { t } = useTranslation(); // Initialize translation hook

    return (
        <SbbTableWrapper>
            <table
                role="table"
                aria-label={t('table.ariaLabel')}
                className="sbb-table">
                <caption style={{ textAlign: 'left', fontSize: '1.25em', marginBottom: '0.5em' }}>
                    {t('table.caption')}
                </caption>
                <thead>
                <tr>
                    <th>{t('table.columns.aboCombination')}</th>
                    <th>{t('table.columns.cost')}</th>
                    <th>{t('table.columns.details')}</th>
                </tr>
                </thead>
                <tbody>
                {bins.map((bin, index) => (
                    <tr
                        key={index}
                        onClick={() => setSelectedBin(bin)}  // Set selected bin when row is clicked
                        style={{
                            cursor: 'pointer'  // Indicate that the row is clickable
                        }}
                    >
                        <td
                            aria-label={t('table.ariaLabelAboCombination', { label: bin.label })}
                        >
                            {bin.label}
                        </td>
                        <td
                            aria-label={t('table.ariaLabelCost', { value: bin.value.toFixed(2) })}
                        >
                            {bin.value.toFixed(2)}
                        </td>
                        <td>
                            {bin.isBest ? (
                                <SbbSecondaryButton onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedBin(bin);
                                }}>
                                    {t('table.viewDetails')}
                                </SbbSecondaryButton>
                            ) : (
                                <SbbTertiaryButton onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedBin(bin);
                                }}>
                                    {t('table.viewDetails')}
                                </SbbTertiaryButton>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </SbbTableWrapper>
    );
};

export default CostTable;
