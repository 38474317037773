import React from 'react';
import { SbbSelect } from '@sbb-esta/lyne-react/select';
import { SbbOption } from '@sbb-esta/lyne-react/option';
import { SbbFormField } from '@sbb-esta/lyne-react/form-field';

interface LanguageSwitcherProps {
    currentLanguage: string;
    onChange: (event: CustomEvent<any>) => void;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ currentLanguage, onChange }) => {
    return (
        <div>
            <SbbFormField size="m">
                <label htmlFor="language-select">Select your language:</label>
                <SbbSelect
                    id="language-select"
                    value={currentLanguage}
                    onDidChange={onChange}
                >
                    <SbbOption value="en">English</SbbOption>
                    <SbbOption value="de">Deutsch</SbbOption>
                    <SbbOption value="fr">Français</SbbOption>
                    <SbbOption value="it">Italiano</SbbOption>
                    <SbbOption value="rm">Rumantsch</SbbOption>
                </SbbSelect>
            </SbbFormField>
        </div>
    );
};

export default LanguageSwitcher;
