import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AppWithLanguage from './AppWithLanguage';

const App = () => {
    return (
        <Router>
            <Routes>
                {/* Default route: Redirect to /en if no language is specified */}
                <Route path="/" element={<Navigate to="/en" replace />} />

                {/* Route for handling languages */}
                <Route path="/:lang/*" element={<AppWithLanguage />} />
            </Routes>
        </Router>
    );
};

export default App;
